export const restrictPages = ["login", "register"];

export const APPCONSTANT = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
  EMAIL_PHONE: /(^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$)|(^[0-9]+$)/
};

export const SEARCH_FILTER = {
  BETWEEN_NUMBER_RANGE: "numberRange",
  BETWEEN_DATE_RANGE: "dateRange",
  SEARCH: "search"
};

export const RESPONSE = {
  SUCCESS: "success",
  FAILURE: "failure"
};
export class Constants {
  _data: any = "data";
  _token: any = "tkn";
  basic = "basic";
  bearer = "bearer";
  authToken = "Authorization";
  staticToken = "Basic UEFZUFJPX1dFQl9VU0VSOlBBWVBST19XRUJfVVNFUl9TRUNSRVQ=";
  resfreshStaticToken = "Basic d2ViQ2xpZW50OndlYkNsaWVudFNlY3JldA==";
  serverErr = "Something went wrong. Please try again after 5  minutes";
  dontHaveRights = "You don't have edit right.";
  noRecords = "No Records Found";
  loading = "loading....";
  maxUploadFileSize = 10 * 1024 * 1024;
  patterns = {
    name: /^[a-zA-Z]((?!.*[-' ]{2})[a-zA-Z '-]?)+$/,
    lastName: /^[a-zA-Z_\-]+$/,
    alphaNumaric: /^[a-zA-Z0-9]*$/,
    phone: /^[0-9]+$/,
    password: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
    //email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    email: /^[A-Za-z0-9]([A-Za-z0-9._-])+@[A-Za-z0-9_-]+([\.-]?\w+)*(\.\w{1,})+$/,
    emailPhone: /(^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$)|(^[0-9]+$)/,
    alphaNumericStartWithOnlyAlphabet: /^[a-zA-Z]([a-zA-Z0-9_]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpace: /^[a-zA-Z]([a-zA-Z0-9\s]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpaceAndUnderScore: /^[a-zA-Z]([a-zA-Z0-9_\s]?)+$/,
    alphaNumericStartWithOnlyAlphabetWithSpaceAndUnderScoreAndOneSCharoneTime: /^[a-zA-Z]((?!.*[-_ ]{2})[a-zA-Z0-9-_\s]?)+$/,
    alphaNumericStartWithAlphaNumericWithSpace: /^[a-zA-Z0-9]([a-zA-Z0-9\s]?)+$/,
    startWithAlphaNumeric: /^[a-zA-Z0-9]((?!.*[-_@*#&^!%$]{2})[\s\S]?)+/,
    anyThing: /^[a-zA-Z0-9]([\s\S]?)+/,
    numberOnly: /^\d+$/,
    numberWithNegative: /^[\+\-]?\d+$/,
    numberDecimalWithNegative: /^[\+\-]?\d*\.?\d{1,2}$/,
    numberDecimalWithNegativeString: "^[\\+\\-]?\\d*\\.?\\d{1,endDecimal}$",
    realComplexQuaterNumber: /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/,
    alphaNumericOnly: /^[a-zA-Z0-9]*$/,
    remarksDescription: /^[a-zA-Z0-9]((?!.*[@!#\$\^%`~_&*()+=\-\[\]\\\';,\\/\{\}\|\":<>\?]{2})(?!.*[ ]{2})(?!.*[.]{2})[a-zA-Z0-9\s\r\n@!#\$\^%`~&_*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\?]?)+$/,
    startWithAlphaOnly: /^[a-zA-Z]([\s\S]?)+/,
    alphaNumericSpaceOnly: /^[a-zA-Z0-9 ]*$/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  };
  dateFormat = "dd/MM/yyyy";
  dateTimeFormat = "dd/MM/yyyy HH:mm:ss";
  headerUserId = "userId";
  loginConst = {
    userId: "user_ID",
    userIdFormats: ["userId", "user_ID", "USER_ID"],
    userInfoObj: "userInfo"
  };
}
